<template>
  <div class="g-section job-container">
    <div class="g-wrap job-top">
      <div class="flex">
         招生报名学生
      </div>
      已招/计划：18/200<el-button type="primary" @click="$router.go(-1)" size="small" icon="el-icon-arrow-left">返回</el-button>
    </div>
    <div class="g-wrap job-bottom">
      <div class="job-item" >
        <div class="item-top">
          <div class="item-left">
            <span class="mb-10">张三</span>
             <p>性别，年龄，籍贯，自我评价等信息</p>
          </div>
          <div class="item-right">
             未处理
          </div>
        </div>
        <div class="item-bottom">
          <div class="item-left">
            <span>报名时间：2020-12-31</span>
          </div>
          <div class="item-right">  
            <el-button type="text" icon="el-icon-check" size="small">通过</el-button>
            <el-button type="text" icon="el-icon-circle-close" size="small">拒绝</el-button>
            <el-button type="text" icon="el-icon-edit" size="small">待定</el-button>
          </div>
        </div>
      </div>
      <div class="job-item" >
        <div class="item-top">
          <div class="item-left">
            <span class="mb-10">李四</span>
             <p>性别，年龄，籍贯，自我评价等信息</p>
          </div>
          <div class="item-right">
             已通过
          </div>
        </div>
        <div class="item-bottom">
          <div class="item-left">
            <span>报名时间：2020-12-31</span>
          </div>
          <div class="item-right">   
          </div>
        </div>
      </div>
      <div class="job-item" >
        <div class="item-top">
          <div class="item-left">
            <span class="mb-10">张三</span>
             <p>性别，年龄，籍贯，自我评价等信息</p>
          </div>
          <div class="item-right">
             已拒绝
          </div>
        </div>
        <div class="item-bottom">
          <div class="item-left">
            <span>报名时间：2020-12-31</span>
          </div>
          <div class="item-right">   
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: ''
    }
  },
  methods:{
    handleShowStudent(){
      this.$router.push()
    }
  }
}
</script>
<style lang="scss" scoped>
.job-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.job-top {
  justify-content: space-between;
  margin-top: 20px;
}
.job-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.job-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e7e8ea;
  border-radius: 3px;
  .item-top {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .item-right {
      display: flex;
      align-items: center;
      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .item-bottom {

    justify-content: space-between;
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #fbfbfb;
    height: 32px;
    align-items: center; 
    padding: 0px 10px;
  }
  .el-button {
    padding: 0px;
  }
}
</style>